import React from 'react' 
import "../styles/index.scss";
import "../styles/output.css";
import "../styles/custom.scss";
import "../styles/global.css";

export default function MenuPage() {
    return    <div className='relative'><div className='max-w-6xl mx-auto'> 
    <div className=" flex space-x-12  	">
      <a href="#home" className='border p-4 bg-white rounded-xl shadow-sm hover:bg-gray-200'>Home</a> 
      <div className="border p-4 bg-white rounded-xl shadow-sm hover:bg-gray-200 parent-dropdown group">
        <button className="dropbtn">Dropdown 
          <i className="fa fa-caret-down"></i>
        </button>
        <div className="dropdown-content shadow-xl">
          <div className='px-40'>
          <div className="header">
            <h2>Mega Menu</h2>
          </div>   
          <div className="row">
            <div className="column">
              <h3>Category 1</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
            <div className="column">
              <h3>Category 2</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
            <div className="column">
              <h3>Category 3</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          </div>
        </div></div>
      </div> 
      <div className="border p-4 bg-white rounded-xl shadow-sm hover:bg-gray-200 parent-dropdown group">
        <button className="dropbtn">One More Dropdown 
          <i className="fa fa-caret-down"></i>
        </button>
        <div className="dropdown-content flex-wrap shadow-xl">
          <div className='bg-gray-200 h-[100px] w-full'>
            some block
          </div>
          <div className="header w-full">
            <h2>Something else here</h2>
          </div>   
          <div className="row">
            <div className="column">
              <h3>Cars</h3>
              <a href="#">S 1</a>
              <a href="#">3 2</a>
              <a href="#">X 3</a>
            </div>
            <div className="column">
              <h3>Tech</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
            <div className="column">
              <h3>Stocks</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          </div>
          <div className="header">
            <h2>Something else here</h2>
          </div>   
          <div className="row">
            <div className="column">
              <h3>Cars</h3>
              <a href="#">S 1</a>
              <a href="#">3 2</a>
              <a href="#">X 3</a>
            </div>
            <div className="column">
              <h3>Tech</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
            <div className="column">
              <h3>Stocks</h3>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          </div>
        </div>
      </div> 
    </div>
    
     
     </div></div>
    
}